import React from "react";
import { Link } from "gatsby";

var activeSection;
var activePage;
var subPages = [];
    subPages["integration"] = ["aws-cloudformation-integration", "aws-terraform-integration", "microsoft-azure-integration", "google-cloud-integration"];
    subPages["billing"] = ["aws-billing-reports", "microsoft-azure-billing-reports"];
    subPages["memory"] = ["amazon-cloudwatch-agent", "amazon-cloudwatch-telegraf", "microsoft-azure-monitor" ];

const isActive = className => ({ isPartiallyCurrent }) => ({
  className: className + (isPartiallyCurrent ? ' active' : ''),
});

const getCurrentPage = (pathname) => {
  const page = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;

  return page.substr(page.lastIndexOf('/') + 1);
};

const isParentLinkActive = (props, section) => {
  const currentLocation = props.pathname
  if (currentLocation.includes(section)) {
    activeSection = section;
    activePage = getCurrentPage(currentLocation);

    return { 
      className: "nav-link dropdown-toggle active"
    }
  }
  else {
    activeSection = '';
    return { 
      className: "nav-link dropdown-toggle"
    }
  }
}

const isSubmenuActive = (props, section) => {
  if(typeof section !== "undefined") {
    section = section.replace("submenu-", "");
  }

  if (section === activeSection) {
    return "submenu-" + section + " sub-menu has-sub-menus list-unstyled collapse in";
  }
  else {
    return "submenu-" + section + " sub-menu has-sub-menus list-unstyled collapse"; 
  }
}

const isSubSubmenuActive = (props, section) => {
  if(typeof section !== "undefined") {
    section = section.replace("submenu-", "");
  }

  if (subPages[section].includes(activePage)) {
    return "submenu-" + section + " sub-sub-menu list-unstyled collapse in";
  }
  else {
    return "submenu-" + section + " sub-sub-menu list-unstyled collapse"; 
  }
}

const isChildLinkActive = (props, section) => {
  if(typeof section !== "undefined") {
    section = section.replace("submenu-", "");
  }

  if (subPages[section].includes(activePage)) {
    return { 
      className: "sub-nav-link dropdown-toggle active"
    }
  }
  else {
    return { 
      className: "sub-nav-link dropdown-toggle" 
    }
  }
}

const DocLink = ({ className, children, ...props }) => (
  <Link getProps={isActive(className)} {...props}>
    {children}
  </Link>
);

const DocSubMenu = ({ className, children, location, ...props }) => (
  <ul className={isSubmenuActive({ location }, className)}>
    {children}
  </ul>
);

const DocSubSubMenu = ({ className, children, location, ...props }) => (
  <ul className={isSubSubmenuActive({ location }, className)}>
    {children}
  </ul>
);

const DocsSidebar = ({ className, location, children, ...props }) => (
  <div id="documentation-toc">
    <nav className="hidden-md hidden-lg navbar sidebar-mobile"> 
      <button className="navbar-toggler collapsed" data-toggle="collapse" data-target="#mobileDocsTOC" aria-controls="mobileDocsTOC" aria-expanded="false" aria-label="Toggle navigation">
        <div className="toggler-icon">
          <span className="icon-bar top-bar"></span>
          <span className="icon-bar middle-bar"></span>
          <span className="icon-bar bottom-bar"></span>
        </div>
        <div className="toggler-text">
          Table of Contents
        </div>
      </button> 
      <div className="collapse" id="mobileDocsTOC">
        <ul className="navbar-nav list-unstyled">
          <li className="nav-item">
            <a className="nav-link" href="/documentation/overview">
              Compute Software Overview
            </a>
          </li>
          <li className="nav-item dropdown">
            <button className="nav-link dropdown-toggle" data-toggle="collapse" data-target=".submenu-setup">
              Setup <span className="arrow rotate"><svg width="24" height="24" viewBox="0 0 24 24"><path fill="#565656" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></span>
            </button>
            <ul className="submenu-setup sub-menu has-sub-menus list-unstyled collapse">
              <li className="dropdown">
                  <button className="sub-nav-link dropdown-toggle" data-toggle="collapse" data-target=".submenu-integration">
                    Connect Your Cloud Provider Accounts <span className="arrow rotate"><svg width="24" height="24" viewBox="0 0 24 24"><path fill="#565656" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></span>
                  </button>
                  <ul className="submenu-integration sub-sub-menu list-unstyled collapse">
                    <li>
                      <a href="/documentation/setup/aws-cloudformation-integration">AWS CloudFormation Integration</a>
                    </li>
                    <li>
                      <a href="/documentation/setup/aws-terraform-integration">AWS Terraform Integration</a>
                    </li>
                    <li>
                      <a href="/documentation/setup/microsoft-azure-integration">Microsoft Azure Integration</a>
                    </li>
                    <li>
                      <a href="/documentation/setup/google-cloud-integration">Google Cloud Integration</a>
                    </li>
                  </ul>
              </li>
              <li className="dropdown">
                  <button className="sub-nav-link dropdown-toggle" data-toggle="collapse" data-target=".submenu-billing">
                    Setup Billing Reports <span className="arrow rotate"><svg width="24" height="24" viewBox="0 0 24 24"><path fill="#565656" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></span>
                  </button>
                  <ul id="submenu-billing" className="submenu-billing sub-sub-menu list-unstyled collapse">
                    <li>
                      <a href="/documentation/setup/aws-billing-reports">AWS Billing Reports</a>
                    </li>
                    <li>
                      <a href="/documentation/setup/microsoft-azure-billing-reports">Microsoft Azure Billing Reports</a>
                    </li>
                  </ul>
              </li>
              <li className="dropdown">
                  <button className="sub-nav-link dropdown-toggle" data-toggle="collapse" data-target=".submenu-memory">
                    Enabling Memory Metrics Collection <span className="arrow rotate"><svg width="24" height="24" viewBox="0 0 24 24"><path fill="#565656" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></span>
                  </button>
                  <ul className="submenu-memory sub-sub-menu list-unstyled collapse">
                    <li>
                      <a href="/documentation/setup/amazon-cloudwatch-agent">Amazon CloudWatch Agent</a>
                    </li>
                    <li>
                      <a href="/documentation/setup/amazon-cloudwatch-telegraf">Amazon CloudWatch Output for Telegraf</a>
                    </li>
                    <li>
                      <a href="/documentation/setup/microsoft-azure-monitor">Microsoft Azure Monitor</a>
                    </li>
                  </ul>
              </li>
              <li>
                 <a className="sub-nav-link" href="/documentation/setup/cloud-accounts-and-global-discounts">Cloud Accounts and Global Discounts</a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <button className="nav-link dropdown-toggle" data-toggle="collapse" data-target=".submenu-users" >
              Users <span className="arrow rotate"><svg width="24" height="24" viewBox="0 0 24 24"><path fill="#565656" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></span>
            </button>
            <ul id="submenuUsers" className="submenu-users sub-menu list-unstyled collapse">
              <li>
                  <a href="/documentation/users/adding-users">Adding Users</a>
              </li>
              <li>
                 <a href="/documentation/users/managing-user-profile">Managing User Profile</a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/documentation/key-metrics">
              Key Metrics
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/documentation/views">
              Views
            </a>
          </li>
          <li className="nav-item hidden">
            <a className="nav-link" href="/documentation/understanding-metrics">
              Understanding Metrics
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/documentation/action-items">
              Action Items
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/documentation/property-groups-and-properties">
              Property Groups and Properties
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/documentation/optimization-approach-and-algorithm">
              Optimization Approach and Algorithm
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/documentation/automation">
              Automation
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <nav className="hidden-sm hidden-xs docs-sidebar sidebar">
      <div className="inner-wrapper-sticky">
        <ul className="nav">
          <li className="nav-item">
            <Link to="/documentation/overview" className="nav-link" partiallyActive={true} activeClassName="active">
              Compute Software Overview
            </Link>
          </li>
          <li className="nav-item">
            <DocLink 
              to="#" 
              data-toggle="collapse" 
              data-target=".submenu-setup" 
              data-section="setup"
              getProps={({ location }) => isParentLinkActive(location, 'setup')}>
              Setup
            </DocLink>
            <DocSubMenu className="submenu-setup" getProps={({ location }) => isSubmenuActive(location, 'setup')}>
              <li>
                <DocLink 
                  to="#" 
                  data-toggle="collapse" 
                  data-target=".submenu-integration" 
                  getProps={({ location }) => isChildLinkActive(location, 'integration')}>
                  <span>Connect Your Cloud Provider Accounts</span>
                </DocLink>
                <DocSubSubMenu className="submenu-integration" getProps={({ location }) => isSubSubmenuActive(location, 'integration')}>
                  <li>
                    <Link to="/documentation/setup/aws-cloudformation-integration" activeClassName="active" partiallyActive={true}>AWS CloudFormation Integration</Link>
                  </li>
                  <li>
                    <Link to="/documentation/setup/aws-terraform-integration" activeClassName="active" partiallyActive={true}>AWS Terraform Integration</Link>
                  </li>
                  <li>
                    <Link to="/documentation/setup/microsoft-azure-integration" activeClassName="active" partiallyActive={true}>Microsoft Azure Integration</Link>
                  </li>
                  <li>
                    <Link to="/documentation/setup/google-cloud-integration" activeClassName="active" partiallyActive={true}>Google Cloud Integration</Link>
                  </li>
                </DocSubSubMenu>
              </li>
              <li className="dropdown">
                <DocLink 
                  to="#" 
                  data-toggle="collapse"
                  data-target=".submenu-billing"
                  getProps={({ location }) => isChildLinkActive(location, 'billing')}>
                  <span>Setup Billing Reports</span>
                </DocLink>   
                <DocSubSubMenu className="submenu-billing" getProps={({ location }) => isSubSubmenuActive(location, 'billing')}>
                  <li>
                    <Link to="/documentation/setup/aws-billing-reports" activeClassName="active" partiallyActive={true}>AWS Billing Reports</Link>
                  </li>
                  <li>
                    <Link to="/documentation/setup/microsoft-azure-billing-reports" activeClassName="active" partiallyActive={true}>Microsoft Azure Billing Reports</Link>
                  </li>
                </DocSubSubMenu>
              </li>
              <li className="dropdown">
                <DocLink 
                  to="#" 
                  data-toggle="collapse"
                  data-target=".submenu-memory"
                  getProps={({ location }) => isChildLinkActive(location, 'memory')}>
                  <span>Enabling Memory Metrics Collection</span>
                </DocLink>  
                <DocSubSubMenu className="submenu-memory" getProps={({ location }) => isSubSubmenuActive(location, 'memory')}>
                  <li>
                    <Link to="/documentation/setup/amazon-cloudwatch-agent" activeClassName="active" partiallyActive={true}>Amazon CloudWatch Agent</Link>
                  </li>
                  <li>
                    <Link to="/documentation/setup/amazon-cloudwatch-telegraf" activeClassName="active" partiallyActive={true}>Amazon CloudWatch Output for Telegraf</Link>
                  </li>
                  <li>
                    <Link to="/documentation/setup/microsoft-azure-monitor" activeClassName="active" partiallyActive={true}>Microsoft Azure Monitor</Link>
                  </li>
                </DocSubSubMenu>
              </li>
              <li>
                <Link to="/documentation/setup/cloud-accounts-and-global-discounts" activeClassName="active" partiallyActive={true}>Cloud Accounts and Global Discounts</Link>
              </li>
            </DocSubMenu>
          </li>
          <li className="nav-item">
            <DocLink 
              to="#" 
              data-toggle="collapse" 
              data-target=".submenu-users" 
              className="nav-link dropdown-toggle"
              getProps={({ location }) => isParentLinkActive(location, 'users')}>
              Users
            </DocLink>
            <DocSubMenu className="submenu-users" getProps={({ location }) => isSubmenuActive(location, 'users')}>
              <li>
                  <Link to="/documentation/users/adding-users" partiallyActive={true} activeClassName="active">
                    Adding Users
                  </Link>
              </li>
              <li>
                  <Link to="/documentation/users/managing-user-profile" partiallyActive={true} activeClassName="active">
                    Managing User Profile
                  </Link>
              </li>
            </DocSubMenu>
          </li>
          <li className="nav-item">
            <Link to="/documentation/key-metrics" className="nav-link" partiallyActive={true} activeClassName="active">
              Key Metrics
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/documentation/views" className="nav-link" partiallyActive={true} activeClassName="active">
              Views
            </Link>
          </li>
          <li className="nav-item hidden">
            <Link to="/documentation/understanding-metrics" className="nav-link" partiallyActive={true} activeClassName="active">
              Understanding Metrics
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/documentation/action-items" className="nav-link" partiallyActive={true} activeClassName="active">
              Action Items
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/documentation/property-groups" className="nav-link" partiallyActive={true} activeClassName="active">
              Property Groups
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/documentation/optimization-approach-and-algorithm" className="nav-link" partiallyActive={true} activeClassName="active">
              Optimization Approach and Algorithm
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/documentation/automation" className="nav-link" partiallyActive={true} activeClassName="active">
              Automation
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
);

export default DocsSidebar;