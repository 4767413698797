import React from "react"
import { graphql } from "gatsby"
import CookieConsent from 'react-cookie-consent';

import SEO from "../components/seo"
import Header from "../components/global/header";
import Footer from "../components/global/footer";
import DocsSidebar from "../components/global/docs-sidebar"
import DocsBreadcrumb from "../components/global/docs-breadcrumb"

import "../styles/vendor/normalize.css"
import "../styles/vendor/bootstrap.css"
import "../styles/vendor/bootstrap-theme.min.css"
import "../styles/vendor/bootstrap-main.css"
import "../styles/vendor/bootstrap-toc.css"
import "../styles/styles.scss"


export default function Docs({ data, pageContext }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <div id="parent">
      <Header/>
      <SEO title={frontmatter.seoTitle} description={frontmatter.seoDescription} />
      <div className="main-content">
        <div className="container">
            <div className="docs">
              <DocsSidebar/>
              <main role="main">
                <DocsBreadcrumb breaddata={pageContext.breadcrumb} crumlabel={frontmatter.breadTitle} section={frontmatter.breadSection} />
                <h1>{frontmatter.title}</h1>
                <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
              </main>
            </div>
        </div>
      </div>
      <Footer/>
      <CookieConsent
        disableStyles={true}
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-tagmanager"
        buttonClasses="cookie-button"
        containerClasses="cookie-container"
        contentClasses="cookie-content">
        We use cookies to collect data to improve your experience on our site, as described in our <a href="/privacy-policy">Privacy Policy</a>. By continuing to browse this site, you agree to this use.
      </CookieConsent>
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title,
        breadTitle,
        breadSection,
        seoTitle,
        seoDescription
      }
    }
  }
`