import { Link } from "gatsby";
import React from "react";

const DocsBreadcrumbs = ({ breaddata, crumlabel, section,  }) => {
  var elementsNew = [];
  var excludedPaths = ["home", "documentation"];
  breaddata.crumbs.forEach(createdBread);
  function createdBread(item) {
    var newlast = item.crumbLabel.replace(/-/g, " ").replace("aws", "AWS");
    if(!excludedPaths.includes(newlast.toString().toLowerCase())) {
      elementsNew.push({pathname: item.pathname, crumlabel: newlast, section: section});
    }
  }

  return (
    <div className="breadcrumb">
      <ul className="breadcrumb__list">
        {elementsNew.map((value, index) => {
          if(typeof value.section !== 'undefined') {
            if(index === 0 ) {
              return <li key={index} className="breadcrumb__item">{value.section}</li>
            }
            else {
              return <li key={index} className="breadcrumb__item"> 
                <Link key={index} to={value.pathname}>{value.crumlabel}</Link>
              </li>
            }
          }
          else {
            return <li key={index} className="breadcrumb__item"> 
              <Link key={index} to={value.pathname}>{value.crumlabel}</Link>
            </li>
          }
        })}
      </ul>
    </div>
  )
}

export default DocsBreadcrumbs;